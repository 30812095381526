import React from "react"
import { graphql } from "gatsby"
import { Grid, Text, Heading, Box, Image, Stack } from "@chakra-ui/react"
import PageLayout from "../components/PageLayout/PageLayout"
import SEO from "../components/seo/Seo"

export default function About({ data }) {
  const { members, description } = data.prismicAbout.data

  function getYear(string) {
    const date = new Date(string)
    return date.getFullYear()
  }

  return (
    <PageLayout>
      <SEO title="Sobre" description={description} />
      <Grid
        gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
        gap={["1.6rem", "1.6rem", "4.8rem"]}
        mb={["1.6rem", "1.6rem", "6.4rem"]}
      >
        {members.map((node, index) => (
          <Stack
            spacing={["1.6rem", "1.6rem", "3.2rem"]}
            direction="row"
            key={`member-${node.member.document.id}-${index}`}
          >
            <Box>
              <Image
                src={node.member.document.data.avatar.thumbnails.avatar.url}
                alt={node.member.document.data.avatar.thumbnails.avatar.alt}
                borderRadius="50%"
                ignoreFallback
              />
            </Box>
            <Box w={["auto", "auto", "32rem"]}>
              <Heading as="h3">{node.member.document.data.name}</Heading>
              <Text fontStyle="italic" mb="2.4rem">
                {node.member.document.data.place_of_origin} -{" "}
                {getYear(node.member.document.data.birth)}
              </Text>
              <div
                dangerouslySetInnerHTML={{ __html: node.member.document.data.description.html }}
              />
            </Box>
          </Stack>
        ))}
      </Grid>
      <Grid
        gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
        gap={["3.2rem", "3.2rem", "4.8rem"]}
      >
        <Box>
          <Heading mb="1.2rem">Atuação</Heading>
          <div dangerouslySetInnerHTML={{ __html: description.html }} />
        </Box>
      </Grid>
    </PageLayout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    prismicAbout {
      data {
        description {
          html
          text
          raw
        }
        members {
          member {
            document {
              ... on PrismicMember {
                id
                data {
                  name
                  description {
                    html
                    text
                    raw
                  }
                  birth
                  place_of_origin
                  avatar {
                    thumbnails {
                      avatar {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
